import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import mock from './mock-data.js'

let data = {
  products: mock,
  cart: [],
  addToCart(product){
    this.cart.push(product);
  },
  removeFromCart(productID){
    this.cart = this.cart.filter(item => item.id !== productID);
  },
  clearCart(){
    this.cart = [];
  }
}

new Vue({
  router,
  data,
  render: h => h(App)
}).$mount('#app')
