<template>
<div class="wrapper">
  <div v-if="notEmpty" class="products">
    <div class="product" v-for="item in cart" :key="item.id">
      <div class="info">
        <h1>{{item.name}}</h1>
        <p>{{item.country}}</p>
      </div>
      <div class="image">
        <img :src="'/images/products/'+item.image">
      </div>
      <div class="price">
        <h2>{{item.price}}</h2>
        <button class="auto" @click="removeFromCart(item.id)">Remove</button>
      </div>
    </div>
  </div>
  <div v-else>
    <h1>The cart is empty</h1>
  </div>
</div>
</template>

<script>
export default {
  name: 'CartList',
  props: {
    cart: Array
  },
  methods: {
    removeFromCart(id) {
      /*let currentProducts = this.$root.$data.products.splice(product => product.id === id);
      let currentProduct = currentProducts[0];*/
      this.$root.$data.removeFromCart(id);
    }
  },
  computed: {
    notEmpty() {
      if (this.$root.$data.cart.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product {
  margin: 10px;
  margin-top: 50px;
  width: 200px;
}

.product img {
  border: 2px solid #333;
  height: 250px;
  width: 200px;
  object-fit: cover;
}

.product .image {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.info {
  background: #F2921D;
  color: #000;
  padding: 10px 30px;
  height: 80px;
}

.info h1 {
  font-size: 16px;
}

.info h2 {
  font-size: 14px;
}

.info p {
  margin: 0px;
  font-size: 10px;
}


.price {
  display: flex;
}

button {
  height: 50px;
  background: #000;
  color: white;
  border: none;
}

.auto {
  margin-left: auto;
}
</style>
