<template>
<div>
  <div class="pure-menu pure-menu-horizontal">
    <ul class="pure-menu-list">
      <li class="pure-menu-item"><a @click="clearCart()" href="#" class="pure-menu-link">Remove all</a></li>
    </ul>
  </div>
  <CartList :cart="cart" />
</div>
</template>

<script>
import CartList from "../components/CartList.vue"
export default {
  name: 'CartView',
  components: {
    CartList
  },
  computed: {
    cart() {
      return this.$root.$data.cart;
    }
  },
  methods: {
    clearCart() {
       this.$root.$data.clearCart()
    }
  }
}
</script>
